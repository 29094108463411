<template>
  <!-- 面包屑 -->

  <div class="user_evaluate">
    <crumbs class="breadcrumb-container" />
    <!-- <transition name="fade-transform" mode="out-in"></transition> -->

    <keep-alive>
      <router-view v-if="$route.meta.keepAlive == true" />
    </keep-alive>

    <transition name="fade-transform" mode="out-in">
      <router-view v-if="$route.meta.keepAlive == false" />
    </transition>
  </div>
</template>
<script>
import Crumbs from "../../../components/crumbs"; //面包屑
export default {
  components: {
    Crumbs
  },
  data() {
    return {
      transitionName: "",
      //筛选表单
      multipleSelection: []
    };
  },
  created() {
    // this.loadUsersData();
  },
  methods: {
    // 提交
    onSubmit() {
      //console.log(this.formInline);
    },
    // 重置
    resetForm(formName) {
      this.$nextTick(() => {
        this.$refs[formName].resetFields();
      });
    },
    handleSelectionChange(val) {
      this.multipleSelection = val;
    }
  },
  watch: {
    //使用watch 监听$router的变化
    $route(to, from) {
      //如果to索引大于from索引,判断为前进状态,反之则为后退状态
      if (to.meta.index > from.meta.index) {
        //设置动画名称
        this.transitionName = "slide-left";
      } else {
        this.transitionName = "slide-right";
      }
    }
  },
  filters: {
    // 性别过滤器
    sexFilter(val) {
      return val == 0 ? "女" : "男";
    },
    // 年龄过滤器
    ageFilter(val) {
      let text = moment(val, "YYYY-MM-DD").fromNow();
      let age = parseInt(text, 10);
      if (isNaN(age)) {
        age = "未知";
      }
      return age;
    }
  }
};
</script>

<style lang="less">
.user_evaluate {
  height: 100%;
}
.breadcrumb-container {
  width: 100%;
  border-bottom: 1px solid rgb(217, 242, 247);
}
</style>